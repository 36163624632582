import React, { useEffect, useState } from 'react';
import axios from "axios";
import {Route, Routes } from 'react-router-dom';
import './style.min.css';
import Home from './components/Home';
import Footer from './parts/Footer';
import Header from './parts/Header';
import Brief from './components/Brief';
import Works from './components/Works';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Privacy from './parts/Privacy';

function App() {
    const [isModalOpen, setModalOpen] = useState(false);
    const [acfData, setData] = useState([]);

    useEffect(() => {
        const fetchAcfData = async () => {
            try {
                const res = await axios.get("https://vivalagrid.com/backend/wp-json/wp/v2/pages/9");
                setData(res.data.acf);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAcfData();
    }, [])

    const handleOpenModal = () => {
        console.log("handleOpenModal called"); // log
        setModalOpen(true);
        // Styl do blokady przewijania
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`;
        window.scrollTo(0, 0);
    };
    
    const handleCloseModal = () => {
        setModalOpen(false);
        // Przywrócenie możliwości przewijania
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };
    
      
	return (
        <div className="App">
            <Header data={acfData} onOpenModal={handleOpenModal} />
            {isModalOpen && <Brief onCloseModal={handleCloseModal} />}
            <Routes>
            <Route exact path="/" element={<Home data={acfData} onOpenModal={handleOpenModal} />} />
            <Route exact path="/works" element={<Works />} />
            <Route exact path="/privacy" element={<Privacy />} />
            </Routes>
            <Footer />
        </div>
	);
}

export default App;
