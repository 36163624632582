import React, { useEffect, useState } from 'react';
import Menu from './menu';
import { Helmet } from 'react-helmet';

function Header({ onOpenModal }) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      const targetScroll = window.innerHeight * 0.2;
      const currentScroll = window.pageYOffset;
      const scrolled = currentScroll > targetScroll;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', checkScroll);

    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  // Przygotuj dynamiczne style
  const ctaStyle = isScrolled
    ? { paddingLeft: '0', transform: 'rotate(-90deg)', top: '20vh', marginTop: '20rem', width: 'inherit', animation: 'connect 1s' }
    : {};
    const handleOpenMenu = () => {
      setIsMenuOpen(true);
      // Styl do blokady przewijania
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
      window.scrollTo(0, 0);
  };
  
  const handleCloseMenu = () => {
      setIsMenuOpen(false);
      // Przywrócenie możliwości przewijania
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
  };

return (
    <div className="header">
            <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Helmet>
      <div id="cta" style={ctaStyle}>
        <button className="cta" onClick={onOpenModal}>Connect</button>
      </div>
      <div className="menu">
        <button className="menu" onClick={handleOpenMenu}>
          <svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect id="line1" x="0.5" y="0.5" width="30" height="3" fill="black" stroke="black"></rect>
            <rect id="line2" x="0.5" y="7.5" width="25" height="3" fill="black" stroke="black"></rect>
            <rect id="line3" y="14" width="4" height="4" fill="#B5150B"></rect>
          </svg>
        </button>
      </div>
      <div>
        {isMenuOpen && <Menu setIsMenuOpen={handleCloseMenu} onOpenModal={onOpenModal} isMenuOpen={isMenuOpen} />}
      </div>
    </div>
  );
}

export default Header;
