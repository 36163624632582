import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { MetaTags } from 'react-meta-tags';

function Home({ data, onOpenModal }) {
    const [originalText, setOriginalText] = useState('');
    const mainTextRegular = useRef(null);
    const mainTextCantata = useRef(null);

    const [searchParams] = useSearchParams();
    const searchParamsFromUrl = searchParams.get("place");    
  
    const mouseOverHandler = () => {
      setOriginalText(mainTextRegular.current.textContent);
      mainTextRegular.current.textContent = mainTextCantata.current.textContent;
    };
  
    const mouseOutHandler = () => {
      mainTextRegular.current.textContent = originalText;
    };

    const [activeTab, setActiveTab] = useState('creation');

    useEffect(() => {
      if(searchParamsFromUrl == "offer"){
        scroller.scrollTo("offer", {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
      }
    }, [])

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const [scrollPercent, setScrollPercent] = useState(0);

    useEffect(() => {
      const calculateScrollPercent = () => {
        const scrollTop = window.pageYOffset;
        const winHeight = window.innerHeight;
        const docHeight = document.documentElement.scrollHeight;

        const totalDocScrollLength = docHeight - winHeight;
        const scrollPosition = Math.floor((scrollTop / totalDocScrollLength) * 100);
        
        setScrollPercent(scrollPosition);
      };

      window.addEventListener('scroll', calculateScrollPercent);

      return () => {
        window.removeEventListener('scroll', calculateScrollPercent);
      };
    }, []);

    useEffect(() => {
        if (mainTextRegular.current && mainTextCantata.current) {
          mainTextRegular.current.addEventListener('mouseover', mouseOverHandler);
          mainTextRegular.current.addEventListener('mouseout', mouseOutHandler);
        }
    
        return () => {
          if (mainTextRegular.current) {
            mainTextRegular.current.removeEventListener('mouseover', mouseOverHandler);
            mainTextRegular.current.removeEventListener('mouseout', mouseOutHandler);
          }
        };
      }, [originalText, mouseOverHandler, mouseOutHandler]);
    
      const [buttonText, setButtonText] = useState('');

      useEffect(() => {
        if(data && data['cta-ekran-4']) {
          setButtonText(data['cta-ekran-4']);
        }
      }, [data]);
    
      const handleMouseOver = () => {
        setButtonText('Contact us →');
      };
    
      const handleMouseOut = () => {
        if(data && data['cta-ekran-4']) {
          setButtonText(data['cta-ekran-4']);
        }
      };

    return(
        <>
          <MetaTags>
            <title>viva/la/grid - #branding agency</title>
            <meta id="meta-description" name="description" content="Tell your story with eye-grabbing and strategic design. We listen and learn your ideas and create this into memorable creations. With great power, comes great designs." />
            <meta id="og-title" property="og:title" content="Carpe diem. Seize the day, boys.Make your projects extraordinary." />
            <meta id="og-description" property="og:description" content="Brand creation, Brand transformation, Brand mind, Brand strategy" />
            <meta id="og-image" property="og:image" content="https://www.yourwebsite.com/path/to/your/logo.jpg" />
            <meta id="og-url" property="og:url" content="https://www.vivalagrid.com" />
            <meta name="robots" content="index, follow"></meta>
            <link rel="icon" type="image/svg+xml" href="https://vivalagrid.com/backend/wp-content/uploads/2023/07/favicon.svg"/>
            <link rel="alternate icon" href="https://vivalagrid.com/backend/wp-content/uploads/2023/07/favicon.ico" />
          </MetaTags>
         <LineComponent />
<div className="start">
<div className="start-text">
    <div className="start-main">
        <p className="main-text" id="main-text">{data['tytul-cabin-medium']}</p>
        <p className="main-text-regular" id="main-text-regular">{data['tytul-cabin-regular']}</p>
        <p className="main-text-cantata" id="main-text-cantata">{data['tytul-cantata-one']}</p>
    </div>
    <div className="redirect">
        <button 
          className="cta-button" 
          id="cta-button" 
          onClick={() => document.getElementById('second').scrollIntoView()}
          >
          {data['cta-ekran-1']}
        </button>
    </div>
</div>
<div className="line line-1" data-top="15"></div>
<div className="line line-2" data-top="40"></div>
<div className="line line-3"data-top="75"></div>
<div className="start-photo">
<img 
            src={data['fota-ekran-1']?.sizes.large} 
            alt={data['fota-ekran-1']?.alt} 
            loading="lazy" 
            onMouseEnter={() => {
              const cursor = document.querySelector("#cursor-pointer");
              cursor.style.display = 'block'; // show custom cursor
            }}
            onMouseLeave={() => {
              const cursor = document.querySelector("#cursor-pointer");
              cursor.style.display = 'none'; // hide custom cursor
            }}
          />
</div>

</div>

<div className="second" id="second" >
        <div className="text">
                <div className="second-main">
                <p className="important-text" id="important-text">
    {data['akapit-ekran-2'] && data['akapit-ekran-2'].split(' ').map((word, index) => (
        <span key={index} className="word">{word} </span>
    ))}
</p>
                </div>
        <div className="redirect">
        <button className="counting" id="counting">{scrollPercent}%</button>
        </div>
    </div>
    <div className="line line-5"data-top="10"></div>
    <div className="line line-6"data-top="35"></div>
    <div className="line line-7"data-top="60"></div>
</div>

<div className="offer" id="offer">
    <div className="second-text">
      <div className="offer-main">
        <h2 className="main-text-regular" id="main-text-regular" ref={mainTextRegular}>
        {data['haslo-ekran-3']}
        </h2>
        <h2 className="main-text-cantata" id="main-text-cantata" ref={mainTextCantata}>
        {data['podhaslo-ekran-3']}
        </h2>
      </div>
    </div>
    <div>
    <div id="tabs" className="tab">
    <button className={`tablinks ${activeTab === 'creation' ? 'active' : ''}`} onClick={() => handleTabClick('creation')}>
    <span className="first-font">{data['tytul-taba-1']}</span>
    <span className="second-font">{data['tytul-taba-1']}</span></button>
    <button className={`tablinks ${activeTab === 'transformation' ? 'active' : ''}`} onClick={() => handleTabClick('transformation')}>
    <span className="first-font">{data['tytul-taba-2']}</span>
    <span className="second-font">{data['tytul-taba-2']}</span></button>
    <button className={`tablinks ${activeTab === 'mind' ? 'active' : ''}`} onClick={() => handleTabClick('mind')}>
    <span className="first-font">{data['tytul-taba-3']}</span>
    <span className="second-font">{data['tytul-taba-3']}</span></button>
    <button className={`tablinks ${activeTab === 'strategy' ? 'active' : ''}`} onClick={() => handleTabClick('strategy')}>
    <span className="first-font">{data['tytul-taba-4']}</span>
    <span className="second-font">{data['tytul-taba-4']}</span></button>
</div>

      {activeTab === 'creation' && (
    <div id="creation" className="tabcontent">
    <p dangerouslySetInnerHTML={{ __html: data['zawartosc-taba-1'] }} />
    </div>
)}
{activeTab === 'transformation' && (
    <div id="transformation" className="tabcontent">
    <p dangerouslySetInnerHTML={{ __html: data['zawartosc-taba-2'] }} />
    </div>
)}
{activeTab === 'mind' && (
    <div id="mind" className="tabcontent">
    <p dangerouslySetInnerHTML={{ __html: data['zawartosc-taba-3'] }} />
    </div>
)}
{activeTab === 'strategy' && (
  <div id="strategy" className="tabcontent">
    <p dangerouslySetInnerHTML={{ __html: data['zawartosc-taba-4'] }} />
</div>

)}

      </div>
      <div className="absolute-image">
      <img src={
      activeTab === 'creation' ? data['fota-ekran-2']?.sizes.large :
      activeTab === 'transformation' ? data['fota-ekran-4']?.sizes.large :
      activeTab === 'mind' ? data['fota-ekran-6']?.sizes.large :
      activeTab === 'strategy' ? data['fota-ekran-8']?.sizes.large : ""
      } 
      className="" 
      alt="" 
      loading="lazy" 
      />
      </div>

      <div className="absolute-image-two">
      <img src={
      activeTab === 'creation' ? data['fota-ekran-3']?.sizes.large :
      activeTab === 'transformation' ? data['fota-ekran-5']?.sizes.large :
      activeTab === 'mind' ? data['fota-ekran-7']?.sizes.large :
      activeTab === 'strategy' ? data['fota-ekran-9']?.sizes.large : ""
      } 
      className="" 
      alt="" 
      loading="lazy" 
      />
      </div>
    <div className="line line-9"data-top="10"></div>
    <div className="line line-10"data-top="40"></div>
    <div className="line line-11"data-top="70"></div>
    <div className="line line-12"data-top="90"></div>
</div>

<div className="fourth">
    <div className="text">
        <div className="fourth-main">
            <p className="important-text" id="important-text">
            {data['akapit-ekran-2_kopia'] && data['akapit-ekran-2_kopia'].split(' ').map((word, index) => (
        <span key={index} className="word">{word} </span>   
        ))}    
            </p>
        </div>
        <div className="redirect">
        <button 
      onClick={onOpenModal}
      onMouseOver={handleMouseOver} 
      onMouseOut={handleMouseOut} 
      className="cta-button" 
      id="cta-button"
    >
      {buttonText}
    </button>        
        <h2 className="main-text-cantata" id="main-text-cantata">{data['podhaslo-ekran-4']}</h2>
        </div>
    </div>
    <div className="photo">
        <img width="400" height="190" src={data['fota-ekran-10']?.sizes.large} className="" alt="" loading="lazy" />
    </div>
    <div className="line line-13"data-top="10"></div>
    <div className="line line-14"data-top="50"></div>
    <div className="line line-15"data-top="70"></div>
    <div className="line line-16"data-top="95"></div>
</div>
    </>
    )
}
export default Home;
export const LineComponent = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const linesRef = useRef(null);

useEffect(() => {
      const onScroll = () => {
          setScrollPosition(window.scrollY);
      };

      window.addEventListener('scroll', onScroll);

      return () => {
          window.removeEventListener('scroll', onScroll);
      };
}, []);

let timeoutId = null;

useEffect(() => {
  if (!linesRef.current) {
    linesRef.current = document.querySelectorAll('.line');
  }

  const updatePositions = () => {
    linesRef.current.forEach((line) => {
      const originalTop = parseFloat(line.getAttribute('data-top'));
      const variance = (Math.random() - 0.5) * 4;
      const newTop = Math.max(0, Math.min(100, originalTop + variance));
      line.style.top = `${newTop}vh`;
    });

    const photo = document.querySelector('.start-photo img');
    const photoframe = document.querySelector('.start-photo');
    const photooffer = document.querySelector('.absolute-image img');
    const photoframeoffer = document.querySelector('.absolute-image');
    const photooffertwo = document.querySelector('.absolute-image-two img');
    const photoframeoffertwo = document.querySelector('.absolute-image-two');
    const photolast = document.querySelector('.photo img');
    const photoframelast = document.querySelector('.photo');
    const windowWidth = window.innerWidth;
    const isMobile = windowWidth < 768;

    if (photo) {
      const line1 = document.querySelector('.line.line-1');
      const line3 = document.querySelector('.line.line-3');
      const line9 = document.querySelector('.line.line-9');
      const line10 = document.querySelector('.line.line-10');
      const line11 = document.querySelector('.line.line-11');
      const line12 = document.querySelector('.line.line-12');
      const line13 = document.querySelector('.line.line-13');
      const line14 = document.querySelector('.line.line-14');
      const line15 = document.querySelector('.line.line-15');
      const line16 = document.querySelector('.line.line-16');

    if (line1 && line3) {
      const line1Position = parseFloat(line1.style.top);
      const line3Position = parseFloat(line3.style.top);
      const line9Position = parseFloat(line9.style.top);
      const line10Position = parseFloat(line10.style.top);
      const line11Position = parseFloat(line11.style.top);
      const line12Position = parseFloat(line12.style.top);
      const line14Position = parseFloat(line13.style.top);
      const line15Position = parseFloat(line15.style.top);
      const line16Position = parseFloat(line16.style.top);
      const zero = 0;
      const minus = -100;

      const photoHeight = Math.max(0, line3Position - line1Position);
      const photoHeightOffer = Math.max(0, line11Position - line9Position);
      const photoHeightOfferTwo = Math.max(0, line12Position - line11Position);
      const photoHeightLast = Math.max(0, line16Position - line14Position);

      if (isMobile) {
        photoframeoffertwo.style.top = `${zero}vh`;
        photoframeoffer.style.top = `${minus}rem`;
      }
      else {
      photo.style.height = `${photoHeight}vh`;
      photoframe.style.top = `${line1Position}vh`;
      photooffer.style.height = `${photoHeightOffer}vh`;
      photoframeoffer.style.top = `${line9Position}vh`;
      photooffertwo.style.height = `${photoHeightOfferTwo}vh`;
      photoframeoffertwo.style.top = `${line11Position}vh`;
      photolast.style.height = `${photoHeightLast}vh`;
      photoframelast.style.top = `${line14Position}vh`;
      }}
    }
  };

if (timeoutId) {
    clearTimeout(timeoutId);
  }

  timeoutId = setTimeout(updatePositions, 100);
}, [scrollPosition]);

return null;
};
