import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import '../../work.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Works = () => {
    const [worksData, setWorks] = useState([]);
    const [randomValues, setRandomValues] = useState([]);
    const smallRefs = useRef([]);
    const midRefs = useRef([]);
    const largeRefs = useRef([]);
    const anotherRefs = useRef([]);

    useEffect(() => {
        const fetchAcfData = async () => {
            try {
                const res = await axios.get("https://vivalagrid.com/backend/wp-json/wp/v2/pages/171");
                setWorks(res.data.acf.work_group);
                setRandomValues(res.data.acf.work_group.map(() => Math.random() * 0.05 + 0.02));
            } catch (err) {
                console.log(err);
            }
        }
        fetchAcfData();
    }, []);

    useEffect(() => {
        const scrollHandler = () => {
            let scrollOffset = window.pageYOffset;
            let windowHeight = window.innerHeight;
    
            // Check if device is mobile
            const isMobile = window.innerWidth <= 768;
    
            [smallRefs, midRefs, largeRefs, anotherRefs].forEach(refs => {
                refs.current.forEach((ref, i) => {
                    if (ref) {
                        let rect = ref.getBoundingClientRect();
                        if (rect.top <= windowHeight && rect.bottom >= 0) {  // element is in the viewport
                            // Reduce the effect if on mobile
                            let offset = isMobile ? scrollOffset * (randomValues[i] / 5) : scrollOffset * randomValues[i];
                            ref.style.transform = `translateY(${offset}px)`;
                        }
                    }
                });
            });
        };
    
        window.addEventListener('scroll', scrollHandler);
    
        // Cleanup function to remove the listener when the component unmounts
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [randomValues, smallRefs, midRefs, largeRefs, anotherRefs]);
    
    

    return (
        <>
        <div className="works">
            {worksData.map((work, index) => {
                let ref;
                if(work.klasa === 'small') {
                    ref = smallRefs;
                } else if(work.klasa === 'mid') {
                    ref = midRefs;
                } else if(work.klasa === 'large') {
                    ref = largeRefs;
                } else {
                    ref = anotherRefs;
                }
                console.log(work);
                return (
                    <div key={index} className={`${work.klasa ? 'photo-' + work.klasa : ''} ${work.inne_klasy ? work.inne_klasy : ''}`}>
                        <div className="maska" ref={el => ref.current[index] = el}>
                        <LazyLoadImage
                            delayMethod="debounce"
                            delayTime="500"
                            threshold="500"
                            alt={work.work.title}
                            effect="blur"
                            height={work.wysokosc}
                            src={work.work.url} // use normal <img> attributes as props
                            width={work.szerokosc} />
                            <div className="hover-text">{work.text}</div> {/* dodajemy div z tekstem */}
                        </div>
                    </div>
                )
            })}
        </div>
        <div className="cta-text">  
        <p id="some-text-cta">Organizing this chaos is the process of creating a brand identity. At #vivalagrid, we work tirelessly, much like the dwarves in Erebor, to ensure that our designs are magical and captivate the right target audience.</p>
        <button class="cta">Let`s grid together!</button>
    </div>
    </>
    );    
}

export default Works;
