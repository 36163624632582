
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

const Privacy = () => {

    const [acfData, setData] = useState([]);

    useEffect(() => {
        const fetchAcfData = async () => {
            try {
                const res = await axios.get("https://vivalagrid.com/backend/wp-json/wp/v2/pages/402");
                setData(res.data);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAcfData();
    }, [])

    console.log(acfData);

    return(
        <div style={{maxWidth: 1200, marginTop: 100,
            marginRight: 'auto',
            marginBottom: 100,
            marginLeft: 'auto', fontSize: 20}}>
            {acfData?.content?.rendered && parse(acfData?.content?.rendered)}
        </div>
    )
}

export default Privacy