import React from "react";

function Footer() {

    return(
        <div class="yourfoot">
            <div class="left">
            <p>©2023</p>
            <a href="#" >privacy</a>
            <a href="mailto:lets@vivalagrid.com" >lets@vivalagrid.com</a>
            </div>
            <div class="right">
            <a href="https://www.behance.net/vivalagrid" target="_blank" rel="noreferrer">#behance →</a>
            <a href="https://www.linkedin.com/company/viva-la-grid-branding-agency" target="_blank" rel="noreferrer">#linkedin →</a>
            <a href="https://www.instagram.com/vivalagrid/" target="_blank" rel="noreferrer">#instagram →</a>
            </div>
        </div>
    )
}

export default Footer;
