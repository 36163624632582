import { Link } from 'react-router-dom'; 
import { scroller } from 'react-scroll';
import '../../menu.css';
import React, { useEffect } from 'react';

const Menu = ({setIsMenuOpen, onOpenModal, isMenuOpen}) => {

    const scrollTo = (section) => {
        setIsMenuOpen(false);
        if (/works/.test(window.location.href)) {
            window.location.replace("/?place=offer");
        }
        scroller.scrollTo(section, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    }

    const handleOfferClick = (section) => {
        setIsMenuOpen(false);
        onOpenModal(); // jeżeli potrzebujesz otworzyć modal po przekierowaniu
    }

    useEffect(() => {
        function setHeight() {
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
        
        window.addEventListener('resize', setHeight);
    
        // Wywołaj raz na początku, aby ustawić wartość początkową
        setHeight();
    
        // Sprzątanie po sobie - usuń event listener gdy komponent jest demontowany
        return () => window.removeEventListener('resize', setHeight);
      }, []);

    return(
        <div
        className={isMenuOpen ? 'menuopen' : 'redirect'}
        >
            <div className="menus">
                <div className="close">
                    <button className="close" id="close" onClick={() => {setIsMenuOpen(false)}}>X</button>
                </div>
                <div className="content">
                    <div className="content-left">
                        <div className="menu-main">
                            <button onClick={() => scrollTo('offer')}>Brand Creation</button>
                            <button onClick={() => scrollTo('offer')}>Brand Transformation</button>
                            <button onClick={() => scrollTo('offer')}>Brand Mind</button>
                            <button onClick={() => scrollTo('offer')}>Brand Strategy</button>
                            <Link to="/works" onClick={() => setIsMenuOpen(false)}>Work</Link>
                            <button onClick={() => {setIsMenuOpen(false); onOpenModal();}}>Say hello!</button>
                        </div>
                        <div className="redirect">
                            <button className="cta-button" id="animate-back">I just wanted to take another look at design.</button>
                        </div>
                    </div>
                    <div className="content-right">
    <div className="contact">
        <a href="mailto:lets@vivalagrid.com">lets@vivalagrid.com</a><br/> 
        PL office:<br/> 
        57 Królewska St., Cracow
    </div>     
    <div className="menu-social">
        <a href="https://www.linkedin.com/company/96058813" target="_blank" rel="noreferrer">go#linkedin</a>
        <a href="https://www.behance.net/vivalagrid" target="_blank" rel="noreferrer">go#behance</a>
        <a href="https://www.instagram.com/vivalagrid/" target="_blank" rel="noreferrer">go#instagram</a>
    </div>
</div>

                </div>
            </div>
        </div>
    )
}

export default Menu;
