import React, { useState, useEffect } from 'react';
import '../../brief.css';
import emailjs from '@emailjs/browser';

function Elipsa({ step }) {
    const elipsaStyle = {
        background: `conic-gradient(from 90deg at 20% 30%, transparent 0deg, rgb(133 25 25) ${step * 30}deg, transparent 1turn), #c23131`,
    };

    return (
        <div id="elipsa" className="elipsa" style={elipsaStyle}></div>
    );
}

function Brief({ onCloseModal }) {
    const [isOpen, setIsOpen] = useState(true);
    const [step, setStep] = useState(1);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [brief, setBrief] = useState({
        need: 'Branding support',
        organizationType: 'Start-up',
        brandingType: 'New branding',
        webType: 'new page',
        budget: '',
        deadline: '',
        problem: '',
        description: '',
        name: '',
        company: '',
        email: '',
        phone: ''
    });

    const handleInputChange = (e) => {
        setBrief({
            ...brief,
            [e.target.name]: e.target.value
        });
    };

    const nextStep = () => {
        if (step < 3) { // Zakładamy, że mamy 3 kroki
            setStep(step + 1);
        }
    }

    const prevStep = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
      
        emailjs.send('service_a129m5d', 'template_jdev2wh', brief, '1XM2lFQGacchkxaqM')
        .then(function() {
        }, function(error) {
            console.log('FAILED...', error);
        });
      
        setIsSubmitted(true);
    };

    useEffect(() => {
        function setHeight() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
      
        window.addEventListener('resize', setHeight);

        // Wywołaj raz na początku, aby ustawić wartość początkową
        setHeight();

        // Sprzątanie po sobie - usuń event listener gdy komponent jest demontowany
        return () => window.removeEventListener('resize', setHeight);
    }, []);  // Ta funkcja uruchomi się raz na początku i na koniec cyklu życia komponentu

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal">
        <>
            <Elipsa step={step} />
            <div className="brief">
            <button className="close" onClick={onCloseModal}></button>
            {step === 1 && !isSubmitted && <p className="main-text-cantata" id="main-text-cantata"><span className="black">Here, your brand</span><span className="white"> comes to life.</span></p>}
            <form id="brief" onSubmit={handleSubmit}>
          {step === 1 && !isSubmitted && (
          <div>
            <label className="head">Looking for?</label>
            <div className="choice">
  <input
    type="radio"
    id="brandingSupport"
    name="need"
    value="Branding support"
    checked={brief.need === "Branding support"}
    onChange={handleInputChange}
  />
  <label htmlFor="brandingSupport">Branding support</label>
</div>

<div className="choice">
  <input
    type="radio"
    id="brandingContractor"
    name="need"
    value="Branding contractor"
    checked={brief.need === "Branding contractor"}
    onChange={handleInputChange}
  />
  <label htmlFor="brandingContractor">Branding contractor</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="webCreator"
    name="need"
    value="Web creator"
    checked={brief.need === "Web creator"}
    onChange={handleInputChange}
  />
  <label htmlFor="webCreator">Web creator</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="strategicMind"
    name="need"
    value="Strategic mind"
    checked={brief.need === "Strategic mind"}
    onChange={handleInputChange}
  />
  <label htmlFor="strategicMind">Strategic mind</label>
</div>

            <div className="navigation">
            <button className="cta" type="button" onClick={() => setStep(2)}>Next</button>
          </div></div>
        )}
        {step === 2 && !isSubmitted && brief.need === "Branding support" && ( 
          <div>
            <label className="head">Organization type</label>
            <div className="choice">
  <input
    type="radio"
    id="startUp"
    name="organizationType"
    value="Start-up"
    checked={brief.organizationType === "Start-up"}
    onChange={handleInputChange}
  />
  <label htmlFor="startUp">Start-up</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="smallCompany"
    name="organizationType"
    value="Small company"
    checked={brief.organizationType === "Small company"}
    onChange={handleInputChange}
  />
  <label htmlFor="smallCompany">Small company</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="mediumCompany"
    name="organizationType"
    value="Medium company"
    checked={brief.organizationType === "Medium company"}
    onChange={handleInputChange}
  />
  <label htmlFor="mediumCompany">Medium company</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="largeCorporation"
    name="organizationType"
    value="Large corporation"
    checked={brief.organizationType === "Large corporation"}
    onChange={handleInputChange}
  />
  <label htmlFor="largeCorporation">Large corporation</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="otherOrganizationType"
    name="organizationType"
    value="Other"
    checked={brief.organizationType === "Other"}
    onChange={handleInputChange}
  />
  <label htmlFor="otherOrganizationType">Other</label>
</div>

            <textarea
              name="description"
              placeholder="What do you need?"
              value={brief.description}
              onChange={handleInputChange}
            />
            <div className="navigation">
            <button className="cta back"  type="button" onClick={() => setStep(1)}>Back</button>
            <button className="cta" type="button" onClick={() => setStep(3)}>Next</button>
          </div></div>
        )}
        {step === 2 && !isSubmitted && brief.need === "Branding contractor" && (
          <div>
            <label className="head">Branding Type</label>
            <div className="choice">
  <input
    type="radio"
    id="newBranding"
    name="brandingType"
    value="New branding"
    checked={brief.brandingType === "New branding"}
    onChange={handleInputChange}
  />
  <label htmlFor="newBranding">New branding</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="rebranding"
    name="brandingType"
    value="Rebranding"
    checked={brief.brandingType === "Rebranding"}
    onChange={handleInputChange}
  />
  <label htmlFor="rebranding">Rebranding</label>
</div>

            <textarea
              name="description"
              placeholder="Describe your vision for this project"
              value={brief.description}
              onChange={handleInputChange}
            />
            <div className="navigation">
            <button className="cta back" type="button" onClick={() => setStep(1)}>Back</button>
            <button className="cta" type="button" onClick={() => setStep(3)}>Next</button>
          </div></div>
        )}
        {step === 2 && !isSubmitted && brief.need === "Web creator" && (
          <div>
            <label className="head">Type of Web Page</label>
            <div className="choice">
  <input
    type="radio"
    id="newPage"
    name="webType"
    value="new page"
    checked={brief.webType === "new page"}
    onChange={handleInputChange}
  />
  <label htmlFor="newPage">New page</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="refreshPage"
    name="webType"
    value="refresh page"
    checked={brief.webType === "refresh page"}
    onChange={handleInputChange}
  />
  <label htmlFor="refreshPage">Refresh page</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="ecommerce"
    name="webType"
    value="ecommerce"
    checked={brief.webType === "ecommerce"}
    onChange={handleInputChange}
  />
  <label htmlFor="ecommerce">Ecommerce</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="portal"
    name="webType"
    value="portal"
    checked={brief.webType === "portal"}
    onChange={handleInputChange}
  />
  <label htmlFor="portal">Portal</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="webApplication"
    name="webType"
    value="web application"
    checked={brief.webType === "web application"}
    onChange={handleInputChange}
  />
  <label htmlFor="webApplication">Web Application</label>
</div>
<textarea
  name="description"
  placeholder="Describe your vision for this project"
  value={brief.description}
  onChange={handleInputChange}
/>
<label className="head">Project Budget</label>
<div className="choice">
  <input
    type="radio"
    id="budget2000To3000"
    name="budget"
    value="2000-3000€"
    checked={brief.budget === "2000-3000€"}
    onChange={handleInputChange}
  />
  <label htmlFor="budget2000To3000">2000-3000€</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="budget3000To5000"
    name="budget"
    value="3000-5000€"
    checked={brief.budget === "3000-5000€"}
    onChange={handleInputChange}
  />
  <label htmlFor="budget3000To5000">3000-5000€</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="budget5000To8000"
    name="budget"
    value="5000-8000€"
    checked={brief.budget === "5000-8000€"}
    onChange={handleInputChange}
  />
  <label htmlFor="budget5000To8000">5000-8000€</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="budget8000To12000"
    name="budget"
    value="8000-12000€"
    checked={brief.budget === "8000-12000€"}
    onChange={handleInputChange}
  />
  <label htmlFor="budget8000To12000">8000-12000€</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="budgetOver12000"
    name="budget"
    value=">12000€"
    checked={brief.budget === ">12000€"}
    onChange={handleInputChange}
  />
  <label htmlFor="budgetOver12000">>12000€</label>
</div>

            <label className="head">Deadline</label>
            <input
  type="date"
  name="deadline"
  value={brief.deadline.split('T')[0]} // Remove time from date
  onChange={handleInputChange}
/>
            <div className="navigation">
            <button className="cta back" type="button" onClick={() => setStep(1)}>Back</button>
            <button className="cta" type="button" onClick={() => setStep(3)}>Next</button>
          </div></div>
        )}
        {step === 2 && !isSubmitted && brief.need === "Strategic mind" && (
          <div>
            <textarea
              name="problem"
              placeholder="Describe your problem"
              value={brief.problem}
              onChange={handleInputChange}
            />
            <label className="head">Project Budget</label>
            <div className="choice">
  <input
    type="radio"
    id="budget1000To3000"
    name="budget"
    value="1000-3000€"
    checked={brief.budget === "1000-3000€"}
    onChange={handleInputChange}
  />
  <label htmlFor="budget1000To3000">1000-3000€</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="budget3000To5000"
    name="budget"
    value="3000-5000€"
    checked={brief.budget === "3000-5000€"}
    onChange={handleInputChange}
  />
  <label htmlFor="budget3000To5000">3000-5000€</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="budget5000To8000"
    name="budget"
    value="5000-8000€"
    checked={brief.budget === "5000-8000€"}
    onChange={handleInputChange}
  />
  <label htmlFor="budget5000To8000">5000-8000€</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="budget8000To12000"
    name="budget"
    value="8000-12000€"
    checked={brief.budget === "8000-12000€"}
    onChange={handleInputChange}
  />
  <label htmlFor="budget8000To12000">8000-12000€</label>
</div>
<div className="choice">
  <input
    type="radio"
    id="budgetOver12000"
    name="budget"
    value=">12000€"
    checked={brief.budget === ">12000€"}
    onChange={handleInputChange}
  />
  <label htmlFor="budgetOver12000">>12000€</label>
</div>

            <div className="navigation">
            <button className="cta back" type="button" onClick={() => setStep(1)}>Back</button>
            <button className="cta" type="button" onClick={() => setStep(3)}>Next</button>
          </div></div>
        )}
        {step === 3 && !isSubmitted && (
          <div>
            <label className="head">There's no place like design</label>
            <input
  type="text"
  name="name"
  placeholder="First and last name"
  value={brief.name}
  onChange={handleInputChange}
  minLength="3"
  required
  onInvalid={(e) => {
    e.target.setCustomValidity("Please enter at least 3 letters");
  }}
  onInput={(e) => {
    e.target.setCustomValidity("");
  }}
/>

            <input
              type="text"
              name="company"
              placeholder="Company"
              value={brief.company}
              onChange={handleInputChange}
            />
<input
  type="email"
  name="email"
  placeholder="Email"
  value={brief.email}
  onChange={handleInputChange}
  required
  onInvalid={(e) => {
    e.target.setCustomValidity("Please enter a valid email address");
  }}
  onInput={(e) => {
    e.target.setCustomValidity("");
  }}
/>
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              value={brief.phone}
              onChange={handleInputChange}
            />
            <div className="navigation">
            <button className="cta back" type="button" onClick={() => setStep(2)}>Back</button>
            <button className="cta" type="submit">Send</button>
          </div></div>
        )}
                        {isSubmitted && (
                    <div className="thanks">
                        Thank you for sending your message, we will contact you within 48 hours. Let's grid together!
                    </div>
                )}
      </form>
            </div>
        </>
        </div>
    );
}

export default Brief;
